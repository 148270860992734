export const newsletter = {
  hero: {
    title: {
      base: "The SET ",
      extensions: ["newsletter.", 1e10],
    },
    lead:
      "We're starting a monthly newsletter to cover engineering and technology-related topics.",
  },
};
